<template>
  <div id="location-map" class="location-map">

  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'


export default {
  name: "Location",

  props: ['lat', 'lng'],

  data() {
    return {
      map: null,
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.map) return;
      const center = [parseFloat(this.lat), parseFloat(this.lng)];
      console.log({center})
      setTimeout(() => {

        delete L.Icon.Default.prototype._getIconUrl
        L.Icon.Default.mergeOptions({
          iconRetinaUrl: require('../assets/marker.svg'),
         iconUrl: require('../assets/marker.svg'),
         shadowUrl: require('leaflet/dist/images/marker-shadow.png')
        })

        this.map = L.map('location-map', {
          center,
          zoomControl: false,
          zoom: 15,
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
            .addTo(this.map);

        L.marker(center).addTo(this.map);

      }, 800);

    },
  }
}
</script>

<style scoped>
#location-map {
  height: 268px;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}
</style>